const testimonials = [
    {
        name: "Sara J.",
        title: "Software Engineer",
        review: "tailoredcv.ai has been a game-changer in my job search. The AI-powered customization helped me land interviews at top tech companies!",
    },
    {
        name: "Michael C.",
        title: "Marketing Specialist",
        review: "I was skeptical at first, but the results speak for themselves. My interview rate has doubled since I started using tailoredcv.ai!",
    },
    {
        name: "Ema E.",
        title: "Data Analyst",
        review: "The AI's ability to highlight my most relevant skills for each job application is incredible. It's like having a personal career coach!",
    },
];

export default function TestimonialsSection() {
    return (
        <section id="testimonials" className="w-full bg-gray-100 py-12 md:py-24 lg:py-32">
            <div className="container px-4 md:px-6">
                <h2 className="bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 bg-clip-text pb-12 text-center font-bold text-3xl text-transparent md:text-4xl">
                    Loved by job seekers everywhere
                </h2>
                <p className="mb-12 text-center text-gray-600 text-xl">
                    tailoredcv.ai is used by professionals to land their dream jobs. Here's what
                    some of them have to say:
                </p>
                <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                    {testimonials.map((testimonial, index) => (
                        <div
                            key={testimonial.name}
                            className="rounded-lg bg-white p-6 shadow-lg transition-transform duration-600 ease-in-out hover:scale-110"
                        >
                            <div className="mb-4 flex items-center">
                                <div>
                                    <h3 className="font-semibold">{testimonial.name}</h3>
                                    <p className="text-gray-600 text-sm">{testimonial.title}</p>
                                </div>
                            </div>
                            <p className="text-gray-600">"{testimonial.review}"</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
