import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Button } from "~/components/ui/button";
import {
    FileUp,
    Database,
    Search,
    PenTool,
    FileDown,
    ThumbsUp,
    Lightbulb,
    Briefcase,
    MessageCircle,
} from "lucide-react";

const items = [
    {
        title: "Create or Upload Your CV",
        icon: FileUp,
        description:
            "Use our CV builder, AI conversion tool for PDFs, or upload your existing jsonresume format CV.",
    },
    {
        title: "Store Your Main CV",
        icon: Database,
        description:
            "We securely store your main CV as your global source of truth for experiences and skills.",
    },
    {
        title: "Tailor for Job Applications",
        icon: Search,
        description:
            "Paste in the job description and title. Our AI analyzes and creates a tailored version of your CV.",
    },
    {
        title: "Fine-tune Your CV",
        icon: PenTool,
        description: "Review and tweak the AI-tailored version to perfect your application.",
    },
    {
        title: "Download Your CV",
        icon: FileDown,
        description:
            "Choose from our PDF templates or download as jsonresume format for use with other tools.",
    },
    {
        title: "Apply with Confidence",
        icon: ThumbsUp,
        description: "Submit your tailored CV and increase your chances of landing that dream job!",
    },
    {
        title: "AI-Powered Skill Suggestions",
        icon: Lightbulb,
        description:
            "Our AI analyzes your experiences and suggests relevant skills you might have forgotten to mention, enhancing your CV's completeness.",
    },
    {
        title: "Industry-Specific Formatting",
        icon: Briefcase,
        description:
            "Choose your industry, and we'll automatically suggest CV templates tht match the expectations of recruiters in that field.",
    },
    {
        title: "Interview Preparation",
        icon: MessageCircle,
        description:
            "Based on your tailored CV, we generate potential interview questions and provide tips to help you prepare effectively.",
    },
];

export default function HowItWorksSection() {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <section
            className="w-full py-12 md:py-24 lg:py-32 dark:from-gray-900 dark:to-gray-800"
            id="how-it-works"
        >
            <div className="container px-4 md:px-6">
                <h2 className="bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 bg-clip-text pb-12 text-center font-bold text-3xl text-transparent md:text-4xl">
                    How We Enhance Your Job Search
                </h2>
                <div className="flex flex-col items-center">
                    <div className="flex flex-wrap justify-center gap-2 mb-8">
                        {items.map((problem, index) => (
                            <Button
                                key={problem.title}
                                variant={activeTab === index ? "default" : "outline"}
                                onClick={() => setActiveTab(index)}
                                className="text-sm md:text-base"
                            >
                                {problem.title}
                            </Button>
                        ))}
                    </div>
                    <AnimatePresence mode="wait">
                        <motion.div
                            key={activeTab}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.3 }}
                            className="max-w-2xl w-full bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6"
                        >
                            <div className="flex items-center space-x-4 mb-4">
                                <div className="w-12 h-12 rounded-full bg-purple-100 dark:bg-purple-900 flex items-center justify-center">
                                    {React.createElement(items[activeTab].icon)}
                                </div>
                                <h3 className="text-2xl font-semibold">{items[activeTab].title}</h3>
                            </div>
                            <p className="text-gray-600 dark:text-gray-300">
                                {items[activeTab].description}
                            </p>
                        </motion.div>
                    </AnimatePresence>
                </div>
            </div>
        </section>
    );
}
