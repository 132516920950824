import { Zap, Target, Users } from "lucide-react";

const features = [
    {
        icon: Zap,
        title: "AI-Powered Customization",
        description: "Our AI tailors your CV to perfectly align with job requirements.",
    },
    {
        icon: Target,
        title: "Increased Interview Chances",
        description: "Customized resumes significantly improve your chances of getting called.",
    },
    {
        icon: Users,
        title: "Technical & Non-Technical Appeal",
        description: "Showcase your skills effectively to diverse audiences.",
    },
];

export default function WhyChooseSection() {
    return (
        <section id="features" className="bg-gray-50 py-16">
            <div className="container mx-auto px-4">
                <h2 className="bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 bg-clip-text pb-12 text-center font-bold text-3xl text-transparent md:text-4xl">
                    Why Choose tailoredcv.ai?
                </h2>
                <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
                    {features.map((feature, index) => (
                        <div
                            key={feature.title}
                            className="group flex flex-col items-center text-center"
                        >
                            <div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-white shadow-lg transition-transform duration-300 ease-in-out group-hover:scale-110">
                                <feature.icon className="h-8 w-8 bg-clip-text" />
                            </div>
                            <h3 className="mb-2 font-semibold text-gray-800 text-xl">
                                {feature.title}
                            </h3>
                            <p className="text-gray-600">{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
