import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import type { MetaFunction } from "@remix-run/cloudflare";
import { Link } from "@remix-run/react";
import { ArrowRight } from "lucide-react";
import { FAQComponent } from "~/components/website/faq";
import Features from "~/components/website/features";
import FloatingCTA from "~/components/website/floating-cta";
import HowItWorksSection from "~/components/website/how-it-works";
import PricingSection from "~/components/website/pricing";
import TestimonialsSection from "~/components/website/testimonials";

export const meta: MetaFunction = () => {
    return [
        { title: "AI-Powered CV Tailoring for Every Job | TailoredCV.ai" },
        {
            name: "description",
            content:
                "Boost your interview chances with AI-tailored CVs. TailoredCV.ai customizes your resume for each job posting, showcasing your true potential. Sign up now and enhance your job applications effortlessly.",
        },
        {
            name: "keywords",
            content:
                "AI CV tailoring, custom CV, job application, CV builder, resume customization, tailored resume, job interview CV, AI-powered resume, CV templates, personalized CV, AI job matching, tailored CV tool",
        },
        {
            property: "og:url",
            content: "https://tailoredcv.ai/",
        },
        {
            property: "og:type",
            content: "website",
        },
        {
            property: "og:description",
            content:
                "Boost your interview chances with AI-tailored CVs. TailoredCV.ai customizes your resume for each job posting, showcasing your true potential. Sign up now and enhance your job applications effortlessly.",
        },
        {
            property: "og:image",
            content: "https://tailoredcv.ai/og-image.png",
        },
        {
            property: "og:title",
            content: "AI-Powered CV Tailoring for Every Job | TailoredCV.ai",
        },
        {
            property: "og:site_name",
            content: "TailoredCV.ai",
        },
        {
            name: "twitter:card",
            content: "summary_large_image",
        },
        {
            property: "twitter:domain",
            content: "tailoredcv.ai",
        },
        {
            property: "twitter:url",
            content: "https://tailoredcv.ai",
        },
        {
            name: "twitter:title",
            content: "AI-Powered CV Tailoring for Every Job | TailoredCV.ai",
        },
        {
            name: "twitter:description",
            content:
                "Enhance your job search with AI-tailored CVs that align with job postings. Increase your interview chances with TailoredCV.ai.",
        },
        {
            name: "twitter:image",
            content: "https://tailoredcv.ai/og-image.png",
        },
        {
            name: "image",
            content: "https://tailoredcv.ai/og-image.png",
        },
    ];
};

export default function Homepage() {
    return (
        <main className="flex-1">
            <section className="w-full overflow-hidden py-12 md:py-24 lg:py-32 xl:py-48">
                <div className="container px-4 md:px-6">
                    <div className="grid gap-6 lg:grid-cols-[1fr_600px] lg:gap-12 xl:grid-cols-[1fr_800px]">
                        <div className="flex flex-col justify-center space-y-4">
                            <div className="space-y-2">
                                <h1 className="animate-fade-in-up font-bold text-3xl tracking-tighter sm:text-5xl xl:text-6xl/none">
                                    Get more{" "}
                                    <span className="bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 bg-clip-text text-transparent">
                                        Interviews
                                    </span>{" "}
                                    with a{" "}
                                    <span className="bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 bg-clip-text text-transparent">
                                        Tailored Resume
                                    </span>{" "}
                                    using{" "}
                                    <span className="bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 bg-clip-text text-transparent">
                                        AI
                                    </span>
                                </h1>
                                <p className="max-w-[600px] animate-fade-in-up text-gray-500 md:text-xl dark:text-gray-400">
                                    Boost your chances of landing interviews by presenting a resume
                                    that highlights your strengths for every job application.
                                </p>
                            </div>

                            <div className="flex animate-fade-in-up flex-col gap-2 min-[400px]:flex-row">
                                <Button
                                    className="plausible-event-name=GetStarted bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 text-white transition-all duration-200 hover:from-pink-700 hover:via-purple-800 hover:to-blue-800 hover:scale-105"
                                    size="lg"
                                    asChild
                                >
                                    <Link to="/auth/signup">Get started</Link>
                                </Button>
                                <Button asChild variant="outline" size="lg" className="group">
                                    <Link to="/contact" className="plausible-event-name=ContactUs">
                                        Contact us{" "}
                                        <ArrowRight className="ml-2 h-4 w-4 transition-transform duration-200 group-hover:translate-x-1" />
                                    </Link>
                                </Button>
                            </div>
                        </div>
                        <div className="flex items-center justify-center">
                            <img
                                src="/dashboard.png"
                                alt="tailoredcv.ai Dashboard"
                                width={800}
                                height={600}
                                className="h-auto w-full animate-float rounded-lg shadow-lg"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <Features />
            <HowItWorksSection />
            <TestimonialsSection />
            <PricingSection />
            <FAQComponent />
            <section
                id="cta"
                className="w-full bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 py-12 md:py-24 lg:py-32"
            >
                <div className="container px-4 md:px-6">
                    <div className="flex flex-col items-center space-y-4 text-center">
                        <div className="space-y-2">
                            <h2 className="font-bold text-3xl text-white tracking-tighter sm:text-4xl md:text-5xl">
                                Ready to Boost Your Career?
                            </h2>
                            <p className="animation-delay-200 mx-auto max-w-[600px] text-purple-100 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                                Join the first wave of professionals using tailoredcv.ai to land
                                their dream jobs.
                            </p>
                        </div>
                        <div className="animation-delay-400 w-full max-w-sm space-y-2">
                            <form className="flex space-x-2" action="/auth/signup">
                                <Input
                                    className="max-w-lg flex-1 bg-white text-purple-900 placeholder-purple-300"
                                    placeholder="Enter your email"
                                    type="email"
                                    name="email"
                                />
                                <Button
                                    type="submit"
                                    className="bg-white text-purple-600 transition-colors duration-200 hover:bg-purple-50 plausible-event-name=GetStarted"
                                >
                                    Get Started
                                </Button>
                            </form>
                            <p className="text-purple-100 text-xs">
                                Limited time offer. Lock in your lifetime discount now.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <FloatingCTA />
        </main>
    );
}
